<template>
  <div class="orders-list">
    <div class="orders-list">
      <div class="row flex-wrap">
        <!--        <vSelect :value="perpage" :options="perpageValues" @input="reloadByPerpage"></vSelect>-->
        <div class="input-field col s12 m6 l6">
          <select v-model="perpage" ref="perpageSelector" @change="reloadByChange">
            <option value="" disabled selected>Выберите количество</option>
            <option v-for="v in perpageValues" :value="v" :key="'opt' + v">{{ v }}</option>
          </select>
          <label>Количество на страницу</label>
        </div>

        <div class="input-field col s12 m6 l6">
          <input id="prikaz-search-nomer" type="text" v-model="Nomer" @input="reloadByChange">
          <span class="helper-text" data-error="wrong" data-success="right">Номер приказа</span>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m12 l12">
          <input id="prikaz-search-name" type="text" v-model="orderName" @input="reloadByChange">
          <span class="helper-text" data-error="wrong" data-success="right">Название приказа</span>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12 m6 l6">
          <select v-model="category" ref="categorySelector" @change="reloadByChange">
            <option v-for="v in categoryValues" :value="v" :key="'optcat' + v" :selected="(v == 'осн') ? 'selected' : ''">
              {{ v }}
            </option>
          </select>
          <label>Категория</label>
        </div>

        <ul class="col s12 m6 l6 pagination">
          <li :class="{ disabled: (Number(page) === 1) }" class="waves-effect">
            <router-link
              :to="{ name: 'orderslist', query: { page: page - 1, perpage, category, name: orderName, nomer: Nomer ? Number(Nomer) : 0 } }"><i
                class="material-icons">chevron_left</i></router-link>
          </li>
          <li v-for="index in pages" :class="{ 'active': page === index, 'waves-effect': page !== index }"
            :key="'li' + index">
            <router-link
              :to="{ name: 'orderslist', query: { page: index, perpage, category, name: orderName, nomer: Nomer ? Number(Nomer) : 0 } }">{{
                index }}</router-link>
          </li>
          <li :class="{ disabled: (Number(page) >= maxpage) }" class="waves-effect">
            <router-link
              :to="{ name: 'orderslist', query: { page: page + 1, perpage, category, name: orderName, nomer: Nomer ? Number(Nomer) : 0 } }"><i
                class="material-icons">chevron_right</i></router-link>
          </li>
        </ul>
      </div>
      <div class="row flex-wrap no-mobile">
        <div class="col s6 m2">Дата</div>
        <div class="col s6 m1">Номер</div>
        <div class="col s12 m4">Название</div>
        <div class="col s6 m2">Создатель</div>
        <div class="col s6 m1">Файлы</div>
        <div class="col s6 m1">Статус</div>
        <div class="col s6 m1">Правка</div>
      </div>
      <!--      ORDERs -->
      <div class="orders-wrapper" v-if="orders && orders.length > 0">
        <div class="order" v-for="(order, oid) in orders" :key="'order' + oid">
          <div class="row flex-wrap">
            <div class="col s6 m2">{{ formatDateTime(order.signdate) }}</div>
            <div class="col s6 m1">{{ order.Nomer == 0 ? 'Проект' : order.Nomer + '-' + order.category }}</div>
            <div class="col s12 m4 font-title">{{ order.name }}</div>
            <div class="col s6 m2">{{ order.creator }}</div>
            <div class="col s6 m1">
              <div class="">

                <ul :id="'dropdown' + order.orderid" class="dropdown-content" tabindex="0">
                  <li v-for="file in order.files" :key="'file' + file.fileid"><a :href="downloadLink(file.link)">{{
                    file.description }}<span class="badge">{{ file.creator }}</span></a>
                  </li>
                </ul>
                <a class="dropdown-trigger brown-text" :data-target="'dropdown' + order.orderid" ref="dropdowns">
                  <i class="material-icons" v-if="order.files && order.files.length > 0">file_download</i>
                  <!--                <i class="material-icons" v-else>not_interested</i>-->
                  <i class="material-icons" v-else></i>
                </a>

              </div>
            </div>
            <div class="col s6 m1">
              <span class="brown-text">
                <i class="material-icons tooltipped" data-position="left" data-tooltip="Приказ подписан"
                  v-if="order.Nomer && Number(order.approved) === 2">stars</i>
                <i class="material-icons tooltipped" data-position="left" data-tooltip="Приказ утверждён"
                  v-if="order.Nomer && Number(order.approved) === 1">star</i>
                <i class="material-icons tooltipped" data-position="left" data-tooltip="Этот приказ в стадии утверждения"
                  v-if="order.Nomer && !order.approved">star_half</i>
                <i class="material-icons tooltipped" data-position="left" data-tooltip="Этот приказ в стадии проекта"
                  v-if="!order.Nomer">star_border</i>
              </span>
            </div>
            <router-link class="col s6 m1" :to="{ name: 'editorder', params: { orderid: order.orderid } }"><span
                class="brown-text"><i class="material-icons tooltipped" data-position="right"
                  data-tooltip="редактировать">edit</i></span></router-link>
          </div>
          <!--        <div class="divider"></div>-->

        </div>
      </div>
      <div class="no-orders" v-else>
        <div class="card-panel">Нет ни одного приказа (документа), удовлетворяющего запросу (или что-то пошло не так..)
        </div>
      </div>
      <!--      End Orders-->
    </div>

  </div>
</template>

<script>
import eventHub from "@/eventHub";
import { mapGetters } from "vuex";
import dateformat from "dateformat";
//import vSelect from 'vue-select'
// import M from 'materialize-css';

export default {
  name: "OrdersList",
  data() {
    return {
      orders: [],
      category: 'осн',
      page: 0,
      perpage: 20,
      perpageDefault: 20,
      ordersCount: 0,
      perpageValues: [5, 10, 20, 50, 100],
      instances: null,
      instancesSel: null,
      Nomer: '',
      orderName: '',
    }
  },
  computed: {
    ...mapGetters('Config', ['getApiRoot', 'categoryValues']),
    maxpage() {
      let mp = 1;
      if (this.ordersCount > this.perpage) {
        mp = Math.ceil(Number(this.ordersCount) / Number(this.perpage));
      }
      return mp;
    },
    pages() {
      let self = this;
      let a = null;
      if (this.page < 4) {
        a = Array.from({ length: 5 }, (_, i) => i + 1);
        if (5 < this.maxpage) a.push(this.maxpage);
      }
      else {
        a = Array.from({ length: 5 }, (_, i) => i + self.page - 2);
        a.unshift(1);
        if (self.page + 2 < this.maxpage) a.push(this.maxpage);
      }
      let b = [];
      a.forEach((v) => v <= this.maxpage ? b.push(v) : '');
      return b;
    },
    uploadPath() {
      return process.env.VUE_APP_LINK + this.getApiRoot + 'orders';
    },
  },

  methods: {
    async fetchOrders() {
      let self = this;
      let nomer = (this.Nomer == '') ? '' : '&nomer=' + this.Nomer;
      let name = (this.orderName == '') ? '' : '&name=' + this.orderName;
      let cat = '&category=' + this.category;
      await this.$http.get(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/?page=' + this.page + '&perpage=' + this.perpage + nomer + name + cat).then((res) => {
        if (res.status == 200 && res.data) {
          if ('count' in res.data && parseInt(res.data.count)) {
            self.orders = res.data.orders;
            self.ordersCount = parseInt(res.data.count);
          } else {
            self.orders = [];
            self.ordersCount = 0;
          }
        }
      }, () => {
      });
    },
    formatDateTime(d) {
      if (d) {
        let date = new Date(d);
        return dateformat(date, 'dd.mm.yyyy');
      } else return '';
    },
    reloadByPerpage() {
      //console.log(this.perpage);
      this.$router.push('/orders/list?page=' + this.page + '&perpage=' + this.perpage + '&category=' + this.category);
    },
    reloadByCategory() {
      //this.$router.push('/orders/list?page=' + this.page+'&perpage='+this.perpage+'&category='+this.category);
      this.reloadByPerpage();
    },
    reloadByNomer() {
      if (this.Nomer || ('nomer' in this.$route.params && !this.Nomer))
        this.$router.push('/orders/list?page=' + this.page + '&perpage=' + this.perpage + '&category=' + this.category + '&nomer=' + this.Nomer);
    },
    reloadByChange() {
      let nomer = '', name = '', perp = '&perpage=' + this.perpage;
      if (this.Nomer || ('nomer' in this.$route.params && !this.Nomer)) nomer = '&nomer=' + this.Nomer;
      if (this.orderName || ('name' in this.$route.params && !this.orderName)) name = '&name=' + this.orderName;
      eventHub.lastCategory = this.category;
      console.log('lastCat in list: ' + eventHub.lastCategory);
      this.$router.push('/orders/list?page=' + this.page + perp + '&category=' + this.category + nomer + name);
    },
    async updateForm(to, from = null) {
      this.destroyCurrent();
      this.page = ('page' in to.query) ? parseInt(to.query.page) : 1;
      this.perpage = ('perpage' in to.query) ? parseInt(to.query.perpage) : this.perpageDefault;
      this.Nomer = ('nomer' in to.query) ? parseInt(to.query.nomer) : '';
      this.orderName = ('name' in to.query) ? to.query.name : '';
      this.category = ('category' in to.query) ? to.query.category : 'осн';
      eventHub.lastCategory = this.category;
      await this.fetchOrders();
      eventHub.$on('reload-prikazy', this.fetchOrders);
      setTimeout(() => {
        this.instances = window.M.FormSelect.init(this.$refs.perpageSelector, {});
        this.instancesSel = window.M.FormSelect.init(this.$refs.categorySelector, {});
        let elems = document.querySelectorAll('.tooltipped');
        this.tooltip_instances = window.M.Tooltip.init(elems, {});
      }, 0);
    },
    destroyCurrent() {
      if (this.instances) {
        this.instances.destroy();
      }
      if (this.instancesSel) {
        this.instancesSel.destroy();
      }
      if (this.tooltip_instances) {
        this.tooltip_instances.forEach((v) => {
          v.destroy();
        });
      }
    },
    downloadLink(link) {
      let c = link.split('/');
      for (let i = 0; i < c.length; i++) {
        c[i] = encodeURIComponent(c[i]);
      }
      return this.uploadPath + c.join('/');
    },
  },
  async mounted() {
    this.updateForm(this.$route);
  },
  updated() {
    let elems = document.querySelectorAll('.dropdown-trigger');
    self.instancesDropdown = window.M.Dropdown.init(elems, { hover: true, constrainWidth: false });
  },
  beforeDestroy() {
    this.destroyCurrent();
  },
  components: {},
  watch: {
    $route(to, from) {
      this.updateForm(to, from);
    },
    Nomer(newval, oldval) {
      console.log(!newval && oldval);
      if (oldval && !newval)
        this.reloadByPerpage();
    },
  },
}
</script>

<style scoped lang="scss">
.order {
  .row {
    display: flex;
    align-items: center;
  }

  .font-title {
    font-weight: 600;
  }
}

.order:nth-child(2n) {
  background-color: whitesmoke;
}


@media (max-width: 800px) {
  .order .row {
    flex-wrap: wrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .no-mobile {
    display: none;
  }
}
</style>
<style>
a {
  cursor: pointer;
}
</style>